import styled from 'styled-components';

const statusColors = {
    "COMPRA IMEDIATA": {
        background: "green",
        color: "var(--color-white)",
        borderColor: "var(--color-white)",
    },
    "PENDÊNCIA PROCESSUAL": {
        background: "orange",
        color: "var(--color-white)",
        borderColor: "var(--color-white)",
    },
    "TESE RUIM": {
        background: "red",
        color: "var(--color-white)",
        borderColor: "var(--color-white)",
    },
    "COMPRA": {
        background: "green",
        color: "var(--color-white)",
        borderColor: "var(--color-white)",
    },
    "COMPRA 24M / EMPRÉSTIMO 12X": {
        background: "var(--color-green)",
        color: "var(--color-white)",
        borderColor: "var(--color-white)",
    },
    "EMPRESA INSOLVENTE": {
        background: "gray",
        color: "var(--color-white)",
        borderColor: "var(--color-white)",
    },
    "COMPRA 36M / EMPRÉSTIMO 18X": {
        background: "#13570a",
        color: "var(--color-white)",
        borderColor: "var(--color-white)",
    },
    "EMPRÉSTIMO 24X": {
        background: "darkgren",
        color: "var(--color-white)",
        borderColor: "var(--color-white)",
    },
    "ANÁLISE MANUAL": {
        background: "yellow",
        color: "var(--color-black)",
        borderColor: "var(--color-black)",
    },
    "VALOR BAIXO": {
        background: "red",
        color: "var(--color-black)",
        borderColor: "var(--color-black)",
    },
    "Erro de Busca, verifique o número do processo pesquisado!": {
        background: "var(--color-danger)",
        color: "var(--color-white)",
        borderColor: "var(--color-black)",
    },
    "Precatório ou Precatório Trabalhista (válido pelo número)": {
        background: "var(--color-yellow)",
        color: "var(--color-black)",
        borderColor: "var(--color-black)",
    }
};

export const ProcessSection = styled.section`
    padding: 0.75rem; /* 12px */
    min-height: 42.5rem; /* 680px */
`;

export const DetalhesContainer = styled.div`
    background: var(--color-light-gray);
    padding: 1.25rem; /* 20px */
    border-radius: 0.625rem; /* 10px */
    box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.1); /* 0 2px 10px */
`;

export const ProcessHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const ProcessNumber = styled.h2`
    font-size: 1.125rem; /* 18px */
    font-weight: bold;
`;

export const Reclamante = styled.h3`
    font-size: 1rem; /* 16px */
    margin-top: 0.3125rem; /* 5px */
    color: var(--color-dark-gray);
`;

export const Tags = styled.div`
    display: flex;
    gap: 0.3125rem; /* 5px */
    margin-top: 0.625rem; /* 10px */
`;

export const Tag = styled.span`
    background: var(--color-gray);
    color: var(--color-dark-gray); /* Texto em cinza escuro para melhor contraste */
    border: 0.0625rem solid var(--color-gray); /* 1px */
    padding: 0.3125rem 0.625rem; /* 5px 10px */
    border-radius: 0.3125rem; /* 5px */
    font-size: 0.75rem; /* 12px */
    display: inline-block;
    transition: opacity 0.3s ease;
`;

export const Status = styled.div`
    color: var(--color-white);
    text-align: center;
    white-space: nowrap;
    padding: 0.3125rem 0.625rem; /* 5px 10px */
    border-radius: 0.3125rem; /* 5px */
    font-size: 0.75rem; /* 12px */
    overflow: hidden;
    text-overflow: ellipsis;
    background: ${({ $status }) => statusColors[$status]?.background || 'var(--color-border)'};
    color: ${({ $status }) => statusColors[$status]?.color || 'var(--color-black)'};
    border-color: ${({ $status }) => statusColors[$status]?.borderColor || 'var(--color-white)'};
`;

export const ProcessInfo = styled.div`
    margin: 1.25rem 0; /* 20px */
    width: 100%;
`;

export const ProcessDetail = styled.p`
    margin: 0.3125rem 0; /* 5px */
    font-size: 0.875rem; /* 14px */
    display: flex;
    gap: 0.375rem; /* 6px */
    flex-wrap: wrap;
    width: 100%;
`;

export const TagsContainer = styled.div`
    margin-bottom: 1.25rem; /* 20px */
    display: flex;
    gap: 0.625rem; /* 10px */
    flex-wrap: wrap;
    transition: opacity 0.3s ease;
    align-items: center;
    
    strong{
        cursor: pointer
    }
`;

export const TagsWrapper = styled.div`
    display: flex;
    transition: all 0.3s ease;
    gap: 0.625rem; /* 10px */
    flex-direction: row;
    flex-wrap: wrap;

    ${({ $show }) =>
        !$show &&
        `
        opacity: 0;
        height: 0;
        overflow: hidden;
    `}

    & > :first-child {
        background: var(--color-green);
        border-color: var(--color-white)
    }
`;

export const PolosContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1.25rem; /* 20px */
`;

export const Polo = styled.div`
    flex: 1;
    width: 50%;
`;

export const NamesList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

export const MapList = styled.div`
    text-overflow: ellipsis;
    max-width: 100%;
    margin-bottom: 0.75rem; /* 12px */
`;

export const BoxName = styled.div`
    display: flex;
    flex-direction: row; 
    align-items: center;
    flex-wrap: wrap;
    width: 100%; 
`;

export const Name = styled.div`
    text-overflow: ellipsis;
    display: inline-block; 
    max-width: calc(100% - 4.9375rem); /* 79px */
    white-space: nowrap; 
    overflow: hidden; 
`;

export const CPFType = styled.li`
    margin: 0.1875rem 0; /* 3px */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    word-wrap: break-word;
    cursor: pointer;

    &:hover {
        color: var(--color-primaryDark);
        text-decoration-line: underline;
    }
`;

export const PrecatórioLink = styled.a`
    text-overflow: ellipsis;
    margin-left: 0.375rem; /* 6px */
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    color: var(--color-primaryDark);
    text-decoration-line: underline;
`;

export const CNPJType = styled.li`
    margin: 0.1875rem 0; /* 3px */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    word-wrap: break-word;
`;

export const AdvogadosContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1.25rem; /* 20px */
    margin: 1.25rem 0; /* 20px */
`;

export const ProcessProgress = styled.div`
    margin-top: 1.25rem; /* 20px */
    font-size: 0.875rem; /* 14px */
`;
