import React  from 'react';
import * as C from './ListRetonoStyles';
import "../../../../styles/variables.css";
import { Status } from '../processoDetail/ProcessoDetailStyles';


const ListRetorno = ({ retCPF, handleHistoryClick }) => {
    const items = Array.isArray(retCPF) ? retCPF : [retCPF];

    // Função para identificar e formatar CPF ou CNPJ
    const formatCPFOrCNPJ = (value) => {
        // Verifica se o valor é null ou undefined e define um valor padrão
        if (!value) {
            return { formatted: null, type: null };
        }
    
        const numericValue = value.replace(/[^\d]/g, '');
    
        if (numericValue.length === 11) {
            // Formata como CPF
            return {
                formatted: numericValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'),
                type: 'CPF'
            };
        } else if (numericValue.length === 14) {
            // Formata como CNPJ
            return {
                formatted: numericValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'),
                type: 'CNPJ'
            };
        }
    
        // Se não for CPF nem CNPJ, retorna o valor original
        return { formatted: null, type: null };
    };

    return (
        <C.CardContainer>
            <C.ItemCount>
                {items.length} {items.length === 1 ? "processo encontrado" : "processos encontrados"}
            </C.ItemCount>
            {items.map((item, index) => {
                // Formata e identifica os documentos
                const cpfInfo = formatCPFOrCNPJ(item?.CPFAtivo ?? item?.Ativos?.[0]?.CPFAtivo);
                const cnpjInfo = formatCPFOrCNPJ(item?.Passivo?.[0]?.CPFPassivo ?? item?.CPFPassivo);

                return (
                    <C.Card key={index} onClick={() => handleHistoryClick(item)}>
                        <C.ProcessoContainer>
                            <C.Label>Processo:</C.Label>
                            <C.ProcessoItem>{item?.NumProcesso ?? 'Informação não disponível'}</C.ProcessoItem>
                        </C.ProcessoContainer>
                        <C.Divider />
                        <C.NomeRow>
                            <C.Column>
                                <C.NomeContainer>
                                    <C.Label>Nome:</C.Label>
                                    <C.Nome>{item?.NomeAtivo ?? item?.Ativos?.[0]?.NomeAtivo ?? 'Informação não disponível'}</C.Nome>
                                </C.NomeContainer>
                                <C.NomeContainer>
                                    <C.Label>{cpfInfo.type ?? 'CPF:'}</C.Label>
                                    <C.CPF>{cpfInfo.formatted ?? 'Informação não disponível'}</C.CPF>
                                </C.NomeContainer>
                                {item?.StatusSN && (
                                    <C.NomeContainer>
                                        <C.Label>Última atualização do Status:</C.Label>
                                        <Status $status={item.StatusSN}>{item.StatusSN ?? 'Informação não disponível'}</Status>
                                    </C.NomeContainer>
                                )}
                            </C.Column>
                            <C.DividerVertical />
                            <C.Column>
                                <C.NomeContainer>
                                    <C.Label>Nome da Empresa:</C.Label>
                                    <C.Nome>{item?.Passivo?.[0]?.NomePassivo ?? item?.NomePassivo ?? 'Informação não disponível'}</C.Nome>
                                </C.NomeContainer>
                                <C.NomeContainer>
                                    <C.Label>{cnpjInfo.type ?? "CNPJ:"}</C.Label>
                                    <C.CNPJ>{cnpjInfo.formatted ?? 'Informação não disponível'}</C.CNPJ>
                                </C.NomeContainer>
                                {item?.Distribuido && (
                                    <C.NomeContainer>
                                        <C.Label>Distribuído:</C.Label>
                                        <C.Nome>{item.Distribuido ? new Date(item.Distribuido).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }) : 'Informação não disponível'}</C.Nome>
                                    </C.NomeContainer>
                                )}
                            </C.Column>
                        </C.NomeRow>
                    </C.Card>
                );
            })}
        </C.CardContainer>
    );
};

export default ListRetorno;