import React from "react";
import * as C from "./ProcessoStyles";
import "../../../styles/variables.css";
import Input from "../../../components/inputs/Input";
import Button from "../../../components/button/Button";
import { BsSearch } from "react-icons/bs";
import Loader from "../../../components/loader/loaderPulse/LoaderPulse";
import ProcessoDetalhes from "./processoDetail/ProcessoDetail";
import MovimentacaoDetail from "./movimentacaoDetail/MovimentacaoDetail";
import ListRetorno from "./listRetorno/ListRetorno";

// Função de formatação de input
const formatInput = (value) => {
    const onlyNumbers = value.replace(/\D/g, '');
    let formattedValue = value;

    if (value.startsWith('(')) {
        if (onlyNumbers.length === 11) {
            // Telefone celular
            formattedValue = onlyNumbers.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        } else if (onlyNumbers.length === 10) {
            // Telefone fixo
            formattedValue = onlyNumbers.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        }
    } else if (onlyNumbers.length === 11) {
        // CPF
        formattedValue = onlyNumbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (onlyNumbers.length === 14) {
        // CNPJ
        formattedValue = onlyNumbers.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else if (onlyNumbers.length === 20) {
        // Processo
        formattedValue = onlyNumbers.replace(/(\d{7})(\d{2})(\d{4})(\d{1})(\d{2})(\d{4})/, '$1-$2.$3.$4.$5.$6');
    }

    return formattedValue;
};

// Regex Patterns
const patterns = {
    trabalhista: /^\d{7}-\d{2}\.\d{4}\.5\.\d{2}\.\d{4}$/,
    precatorio: /^\d{7}-\d{2}\.\d{4}\.8\.\d{2}\.\d{4}$/,
    cpf: /^\d{3}\.\d{3}\.\d{3}-\d{2}$|^\d{11}$/,
    cnpj: /^\d{14}$|(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})-(\d{2})/,
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    telefone: /^\(\d{2}\)\s?\d{4,5}-\d{4}$/
};

// Função para determinar o tipo de busca
const determineSearchType = (value) => {
    if (patterns.trabalhista.test(value)) return 'trabalhista';
    if (patterns.precatorio.test(value)) return 'precatorio';
    if (patterns.cpf.test(value)) return 'cpf';
    if (patterns.cnpj.test(value)) return 'cnpj';
    if (patterns.email.test(value)) return 'email';
    if (patterns.telefone.test(value)) return 'telephone';
    return 'nome'; // Presume nome se não encaixar nas categorias acima
};

// Componente principal
const Processo = ({
    search, 
    setSearch, 
    processData,
    moveProcessData,
    noResults,
    retCPF,
    handleSearch,
    loading,
    activeTab,
    handleHistoryClick,
    handleTabChange,
    handleCPFSearch,
    handleSearchClick,
    hasSubPermission,
}) => {
    // Lógica de submit de pesquisa
    const onSubmit = (e) => {
        e.preventDefault();
        const trimmedSearch = search.trim();
        if (!trimmedSearch) return;

        const type = determineSearchType(trimmedSearch);
        const cleanSearch = type === 'telephone' ? formatInput(trimmedSearch).replace(/[^\d]/g, '') : trimmedSearch;

        if (type === 'cpf' || type === 'cnpj' || type === 'email' || type === 'telephone'|| type === 'nome') {
            handleCPFSearch(cleanSearch, type);
        } else {
            handleSearch(cleanSearch, type);
        }
    };

    return (
        <C.ProcessAside>
            <C.SearchContainer onSubmit={onSubmit}>
                <C.InputBody onClick={handleSearchClick}>
                    <BsSearch />
                    <Input
                        type="text"
                        placeholder="Busca por CPF, nome, telefone, email, processo, CNPJ ou empresa"
                        id="input_search"
                        autocomplete="off"
                        value={search}
                        onChange={(e) => setSearch(formatInput(e.target.value))}
                    />
                </C.InputBody>
                <Button Text="Consultar" type="submit" />
            </C.SearchContainer>

            {/* Resultados de processo */}
            {processData && (
                <C.PageContainer>
                    <C.HeaderResults>
                        <C.NavHeaderResults
                            $selected={activeTab === 'processo'}
                            onClick={() => handleTabChange('processo')}
                        >
                            Processo
                        </C.NavHeaderResults>
                        {moveProcessData && moveProcessData.length > 0 && hasSubPermission(1, 4) && (
                            <C.NavHeaderResults
                                $selected={activeTab === 'movimentacoes'}
                                onClick={() => handleTabChange('movimentacoes')}
                            >
                                Movimentações
                            </C.NavHeaderResults>
                        )}
                    </C.HeaderResults>

                    {activeTab === 'processo' && (
                        <C.ResultsContainer $selected={activeTab}>
                            <ProcessoDetalhes process={processData} handleCPFSearch={handleCPFSearch} hasSubPermission={hasSubPermission} />
                        </C.ResultsContainer>
                    )}
                    {activeTab === 'movimentacoes' && moveProcessData && moveProcessData.length > 0 && hasSubPermission(1, 4) && (
                        <C.ResultsContainer $selected={activeTab}>
                            <MovimentacaoDetail moveProcessData={moveProcessData} />
                        </C.ResultsContainer>
                    )}
                </C.PageContainer>
            )}

            {/* Resultados de CPF */}
            {retCPF && (
                <C.ResultsContainer>
                    <ListRetorno retCPF={retCPF} handleHistoryClick={handleHistoryClick} />
                </C.ResultsContainer>
            )}

            {/* Sem resultados */}
            {noResults && <C.NoResultsMessage>{noResults}</C.NoResultsMessage>}

            {/* Loader */}
            {loading && (
                <C.BoxLoader>
                    <Loader />
                </C.BoxLoader>
            )}
        </C.ProcessAside>
    );
};

export default Processo;
