import React, { useState, useEffect } from "react";
import "../../../styles/variables.css";
import * as C from "./AdvogadosStyle";
import { fetchAdvogados, removeAdvogado, Logis } from '../../../service/ServiceApi';
import AdvogadosSettings from "./advogadosSettings/AdvogadosSettings";
import { TableRow } from "./rowTable/RowTableAdvogados";
import HeaderTable from "../../../components/table/headerTable/HeaderTable";
import SearchBar from "../../../components/search/Search";
import { FaAngleLeft, FaAnglesRight, FaAngleRight, FaAnglesLeft  } from "react-icons/fa6";



//tela de login sendo renderizada o tempo todo [12/08/2024]


const Advogados = ({hasSubPermission}) =>{


    const [userRoster, setUserRoster] = useState([]);
    const [fullRoster, setFullRoster] = useState([]);
    const [userRosterEdit, setUserRosterEdit] = useState({});
    const [open, setOpen] = useState(false);
    const [openUserInfoIndex, setOpenUserInfoIndex] = useState([]);
    const [openSearch, setOpenSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isAscending, setIsAscending] = useState();
    const [activeSort, setActiveSort] = useState('advogado');

  



    // controlador de estado para tela de cadastro

    const openCkick = () => {
        setOpen((prevState) => !prevState);
    };

    const openSearchClick = () => {
        setOpenSearch((prevState) => !prevState);
    };

    const fetchData = async () => {
        try {
            const data = await fetchAdvogados();
            setUserRoster(
                data
                .filter((advogado) => advogado.ativo === 'S')
                .sort((a, b) => a.advogado.localeCompare(b.advogado, undefined, { sensitivity: 'base' }))
            );
            setFullRoster(data)
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleRemove = async (advogado) => {
        try {
            await removeAdvogado(advogado);
            await fetchData();

            Logis({
                route: `/api/v1/advogados`,
                descricao: `removido advogado ${advogado.advogado} da lista`,
                user_id: JSON.parse(localStorage.getItem("user_token"))?.user?.id
                
            });

        } catch (error) {
            console.error('Erro ao remover o advogado:', error);
        }
    };

    const handleEditClick = (user) => {
        setUserRosterEdit(user);
        setOpen(true);
    };

    const toggleUserInfo = (userPageIndex) => {
        if (openUserInfoIndex.includes(userPageIndex)) {
            setOpenUserInfoIndex(openUserInfoIndex.filter(id => id !== userPageIndex));
        } else {
            setOpenUserInfoIndex([...openUserInfoIndex, userPageIndex]);
        }
    };
    // função para ordenar nome quando o usuario clica no nome

    const ordenePorChave = (chave, isDate = false) => {
        if (chave === activeSort) {
            // Alterna a direção de ordenação na mesma coluna
            setIsAscending((prev) => !prev);
        } else {
            // Define a nova coluna ativa e ordena de forma crescente por padrão
            setActiveSort(chave);
            setIsAscending((prev) => !prev);
        }
    
        setUserRoster((prevRoster) => {
            const sortedRoster = [...prevRoster].sort((a, b) => {
                if (isDate) {
                    const dateA = new Date(a[chave]);
                    const dateB = new Date(b[chave]);
                    return isAscending ? dateA - dateB : dateB - dateA;
                }
                return isAscending 
                    ? a[chave].localeCompare(b[chave], undefined, { sensitivity: 'base' }) 
                    : b[chave].localeCompare(a[chave], undefined, { sensitivity: 'base' });
            });
            return sortedRoster;
        });
    };

    const ordeneName = () => ordenePorChave('advogado');
    const ordeneOAB = () => ordenePorChave('oab');
    const ordeneSociedade = () => ordenePorChave('sociedade');
    const ordeneObservacao = () => ordenePorChave('observacao');
    const ordeneData = () => ordenePorChave('dtcadastro', true);

    // contante que envia quantas colunas e suas funções para a tabela no header [12/08/2024]

    const headers = [
        { label: "Data", onClick: ordeneData, key: "dtcadastro" },
        { label: "Nome", onClick: ordeneName, key: "advogado" },
        { label: "OAB/UF", onClick: ordeneOAB, key: "oab" },
        { label: "Sociedade", onClick: ordeneSociedade, key: "sociedade" },
        { label: "Observações", onClick: ordeneObservacao, key: "observacao" },
    ];



    // cria paginação para usuarios

    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 20;

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };
    
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const generatePageNumbers = () => {
        const maxPageNumbers = 5; // Quantidade máxima de números de página a serem exibidos
        let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
        let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);
    
        if (endPage - startPage < maxPageNumbers - 1) {
            startPage = Math.max(1, endPage - maxPageNumbers + 1);
        }
    
        return [...Array(endPage - startPage + 1)].map((_, i) => startPage + i);
    };

    useEffect(() => {
        const filtered = userRoster.filter(user => {
            const advogado = user.advogado?.toLowerCase() || '';
            const oab = user.oab?.toLowerCase() || '';
            const sociedade = user.sociedade?.toLowerCase() || '';
    
            return (
                advogado.includes(searchTerm.toLowerCase()) ||
                oab.includes(searchTerm.toLowerCase()) ||
                sociedade.includes(searchTerm.toLowerCase())
            );
        });
        setFilteredUsers(filtered);
        setCurrentPage(1); // Reset to first page on new search
    }, [searchTerm, userRoster]);


    return (
        <C.UsuariosContainer>
            <C.UsuariosSection>
                {openSearch && (
                    <C.SearchBar>
                        <SearchBar
                            onSearch={setSearchTerm}
                            onOpenSearch={openSearchClick}
                            placeholder={"Busca por nome, OAB ou sociedade"}
                            openSearch={openSearch}
                        />
                    </C.SearchBar>
                )}
                <HeaderTable
                    headers={headers}
                    columns={headers.length+1}
                    data={currentUsers}
                    activeColumn = { activeSort }
                    isAscending = { isAscending } 
                    onButtonClick={openCkick}
                    onOpenSearch={openSearchClick}
                    openSearch={openSearch}
                    hasBtn={hasSubPermission(3,6)}
                    renderRow={(user, index) => {
                        const userPageIndex = `${user.id}`;
                        return (
                            <TableRow
                                key={userPageIndex}
                                advogados={user}
                                index={index}
                                isOpen={openUserInfoIndex.includes(userPageIndex)}
                                toggleUserInfo={() => toggleUserInfo(userPageIndex)}
                                handleEditClick={handleEditClick}
                                handleRemove={handleRemove}
                                hasSubPermission = {hasSubPermission}
                            />
                        );
                    }}
                    
                />
                {totalPages > 1 && (
                    <C.PaginationContainer>
                        <C.PaginationButton
                            onClick={() => handlePageChange(1)}
                            disabled={currentPage === 1}
                        >
                            <FaAnglesLeft />
                        </C.PaginationButton>
                        <C.PaginationButton
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            <FaAngleLeft />
                        </C.PaginationButton>
                        {generatePageNumbers().map((pageNumber) => (
                            <C.PaginationButton
                                key={pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                                $active={pageNumber === currentPage}
                            >
                                {pageNumber}
                            </C.PaginationButton>
                        ))}
                        <C.PaginationButton
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            <FaAngleRight />
                        </C.PaginationButton>
                        <C.PaginationButton
                            onClick={() => handlePageChange(totalPages)}
                            disabled={currentPage === totalPages}
                        >
                            <FaAnglesRight />
                        </C.PaginationButton>
                    </C.PaginationContainer>
                )}
            </C.UsuariosSection>
            {open && hasSubPermission(3, 6) &&(
                <AdvogadosSettings 
                    userRoster={userRoster}
                    setUserRoster={setUserRoster}
                    fullRoster = { fullRoster }
                    userRosterEdit={userRosterEdit}
                    setUserRosterEdit={setUserRosterEdit}
                    fetchData={fetchData}
                />
            )}
        </C.UsuariosContainer>
    );
};

export default Advogados